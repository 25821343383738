<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>商品管理</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <a-row v-if="!isBatch" class="button-container">
      <a-space>
        <a-button type="primary" @click="onSet">
          设置整车采购标准
        </a-button>
        <a-button type="primary" @click="onBatch">
          批量处理
        </a-button>
      </a-space>
    </a-row>
    <a-row v-else class="button-container-second">
      <a-space>
        <a-button type="white" @click="onBatchChange(true)">批量上架</a-button>
        <a-button type="white" @click="onBatchChange(false)">批量下架</a-button>
        <a-button type="white" @click="onCancel">取消</a-button>
      </a-space>
    </a-row>
    <div class="content-container">
      <a-form :form="form" class="advanced-search-form">
        <a-row>
          <a-col :span="21">
            <a-row>
              <a-col :span="7">
                <a-form-item label="商品名称">
                  <a-input v-decorator="['productName']" />
                </a-form-item>
              </a-col>
              <a-col :span="7">
                <a-form-item label="商品编码">
                  <a-input v-decorator="['productCode']" />
                </a-form-item>
              </a-col>
              <a-col :span="7">
                <a-form-item label="商品类型">
                  <a-select
                    v-decorator="['productType', { initialValue: '' }]"
                    :options="typeList"
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="7">
                <a-form-item label="商品状态">
                  <a-select
                    v-decorator="['commodityStatus', { initialValue: -1 }]"
                    :options="statusList"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="2" :offset="1">
                <a-button
                  type="primary"
                  icon="search"
                  class="search-button"
                  @click="onSearch"
                >
                  搜索
                </a-button>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="3"></a-col>
        </a-row>
      </a-form>
      <div class="main-content">
        <a-table
          :data-source="tblData"
          :columns="tblColumns"
          :pagination="pagination"
          :loading="loading"
          row-key="productId"
          :row-selection="
            isBatch
              ? { selectedRowKeys: selectedRowKeys, onChange: onSelectChange }
              : null
          "
          :scroll="{ x: 1200 }"
          @change="onPage"
        >
          <div
            slot="productImg"
            v-viewer
            slot-scope="record"
            class="product-img-container"
          >
            <img
              v-if="record.productImages && record.productImages.length !== 0"
              :src="imageBaseUrl + record.productImages[0].fileUrl"
              :data-source="imageBaseUrl + record.productImages[0].fileUrl"
              alt="产品图"
            />
            <div v-else></div>
          </div>
          <a-space slot="action" slot-scope="record">
            <span class="btn-operation" @click="onDetail(record)">详情</span>
            <span class="btn-operation" @click="adjustPrice(record)">
              调整价格
            </span>
            <span v-if="record.onshelfFlag === true" class="btn-operation">
              下架
            </span>
            <span v-else class="btn-operation">上架</span>
          </a-space>
        </a-table>
      </div>
    </div>
    <!--  调整价格弹窗  -->
    <a-modal v-model="adjustmentVisible" title="调整价格">
      <div class="info-container">
        <div class="info-img"></div>
        <div class="info-content">
          <div>商品名称：{{ commodityInfo.commodityName }}</div>
          <div>商品编号：{{ commodityInfo.commodityId }}</div>
        </div>
      </div>
      <a-divider />
      <a-form :form="adjustmentForm">
        <a-row>
          <a-col>
            <a-form-item label="进货价" class="form-item">
              <a-input
                v-decorator="[
                  'wholesalePrice',
                  { rules: [{ required: true, message: '请输入进货价' }] }
                ]"
                class="input"
              />
            </a-form-item>
          </a-col>
          <a-col>
            <a-form-item label="零售价格" class="form-item">
              <a-input
                v-decorator="[
                  'retailPrice',
                  { rules: [{ required: true, message: '请输入零售价格' }] }
                ]"
                class="input"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
    <!--  设置整车采购标准弹窗  -->
    <a-modal v-model="setStandardVisible" title="整车采购标准">
      <div class="set-standard-container">
        <a-input class="input-standard" />
        <div class="unit">m³</div>
        <div class="standard-word">≤ 商品总体积 ≤</div>
        <a-input class="input-standard" />
        <div class="unit">m³</div>
      </div>
      <div class="set-standard-container-two">
        <a-input class="input-standard" />
        <div class="unit">吨</div>
        <div class="standard-word">≤ 商品总重量 ≤</div>
        <a-input class="input-standard" />
        <div class="unit">吨</div>
      </div>
    </a-modal>
  </a-layout-content>
</template>

<script>
import {
  fetchCommodityList,
  modifyCommodityStatus
} from "../../services/CommodityService";
import { imageBaseUrl } from "../../services/HttpService";
import moment from "moment";

const tblColumns = [
  {
    title: "商品图",
    key: "productImg",
    scopedSlots: { customRender: "productImg" },
    width: "10%"
  },
  {
    title: "商品名称",
    dataIndex: "productName"
  },
  {
    title: "商品编码",
    dataIndex: "productCode",
    width: "160px"
  },
  {
    title: "进货价",
    dataIndex: "wholesalePrice"
  },
  {
    title: "零售价格",
    dataIndex: "retailPrice"
  },
  {
    title: "商品类型",
    dataIndex: "productType"
  },
  {
    title: "商品状态",
    dataIndex: "commodityStatus"
  },

  {
    title: "发布时间",
    dataIndex: "createTime",
    width: "210px"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    fixed: "right"
  }
];
export default {
  data() {
    return {
      imageBaseUrl,
      form: this.$form.createForm(this),
      tblData: [
        {
          commodityName: "电动车电摩车把",
          commodityId: "874689839",
          wholesalePrice: "￥56",
          retailPrice: "￥70",
          commodityType: "配件",
          commodityStatus: "上架",
          createTime: "2021-03-04"
        }
      ],
      tblColumns,
      pagination: {
        hideOnSinglePage: true
      },
      loading: false,
      typeList: [
        { value: "", label: "全部" },
        { value: "整车", label: "整车" },
        { value: "配件", label: "配件" }
      ],
      statusList: [
        { value: -1, label: "全部" },
        { value: 1, label: "上架" },
        { value: 0, label: "下架" }
      ],
      selectedRowKeys: [],
      isBatch: false,
      adjustmentVisible: false,
      commodityInfo: {},
      adjustmentForm: this.$form.createForm(this),
      setStandardVisible: false
    };
  },
  mounted() {
    this.onFetchList();
  },
  methods: {
    // 打开设置采购标准弹窗
    onSet() {
      this.setStandardVisible = true;
    },
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.onFetchList();
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    onBatch() {
      this.isBatch = true;
    },
    onCancel() {
      this.isBatch = false;
    },
    // 打开调整价格弹窗
    adjustPrice(record) {
      this.adjustmentVisible = true;
      this.commodityInfo = record;
    },
    // 查看详情
    onDetail(record) {
      this.$router.push({
        name: "CommodityDetail",
        params: { productId: record.productId }
      });
    },
    // 获取商品列表
    onFetchList() {
      this.loading = true;
      const params = {
        commodityStatus: this.form.getFieldsValue().commodityStatus,
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 15,
        productCode: this.form.getFieldsValue().productCode,
        productName: this.form.getFieldsValue().productName,
        productType: this.form.getFieldsValue().productType,
        productStatus: -1
      };
      fetchCommodityList(params)
        .then(resp => {
          if (resp.data.code === "FAILED") {
            this.$message.error(resp.data.errorMsg);
          } else {
            this.loading = false;
            const data = resp.data.data;
            this.tblData = data.records;
            this.tblData.forEach(item => {
              item.createTime = moment(item.createTime).format(
                "YYYY-MM-DD HH:MM:SS"
              );
              if (item.onshelfFlag === false) {
                item.commodityStatus = "下架";
              } else {
                item.commodityStatus = "上架";
              }
            });
            const pagination = { ...this.pagination };
            pagination.current = data.pageNum;
            pagination.total = data.total;
            pagination.pageSize = data.pageSize;
            pagination.showTotal = function(total, range) {
              return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
            };
            this.pagination = pagination;
          }
        })
        .catch(error => {
          if (error) {
            this.loading = false;
          }
        });
    },
    onSearch() {
      this.onFetchList();
    },
    // 批量处理
    onBatchChange(status) {
      if (this.selectedRowKeys.length === 0) {
        this.$message.error("请至少选择一个产品");
        return;
      }
      const param = {
        onshelfFlag: status,
        productIds: this.selectedRowKeys
      };
      modifyCommodityStatus(param)
        .then(resp => {
          if (resp.data.code === "SUCCESS") {
            this.$message.success("操作成功");
            this.selectedRowKeys = [];
            this.onSearch();
          }
        })
        .catch(error => {
          if (error) {
            console.log(error);
          }
        });
    }
  }
};
</script>

<style scoped>
.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}
.button-container {
  margin-right: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: right;
}
.button-container-second {
  margin-top: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: right;
}
.search-button {
  transform: translateY(4px);
}
.button-end {
  margin-left: 40px;
}
.btn-operation {
  cursor: pointer;
}
.info-container {
  display: flex;
}
.info-content {
  display: flex;
  flex-direction: column;
}
.form-item {
  display: flex;
}
.input {
  width: 180px;
}
.set-standard-container {
  display: flex;
  align-items: flex-end;
}
.input-standard {
  width: 100px;
  margin-right: 10px;
}
.standard-word {
  height: 32px;
  display: flex;
  align-items: center;
  margin: 0 10px;
}
.set-standard-container-two {
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
}
.unit {
  width: 20px;
}
.btn-operation {
  cursor: pointer;
}
.product-img-container {
  width: 100%;
}
.product-img-container img {
  width: 100%;
  object-fit: contain;
}
</style>
